import React, { useState, useEffect } from "react";
import logo from "../Assets/images/Orinova Logo 2.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Nav() {
  const [navBg, setNavBg] = useState(true); //state for NavBar background change
  const [navToggle, setNavToggle] = useState(false); // state for mobile navigation toggle

  // Change NavBar background color on scroll
  const changeNavBg = () => {
    const scrollSize = window.scrollY;
    if (scrollSize >= 90) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  return (
    <>
      {/* Desktop Nav Bar */}
      <nav className={navBg ? "nav active" : "nav"}>
        <div id="logo">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <ul>
            <li>
              <Link smooth to="/">
                Home
              </Link>
            </li>
            <li>
              <HashLink smooth to="./#services">
                Services
              </HashLink>
            </li>
            <li>
              <Link to="/About">About us</Link>
            </li>
            <li>
              <HashLink smooth to="/#contactContainer">
                Contact us
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile Nav Bar */}
      <div id="mobileNavContainer">
        <div id="mobileNavButton">
          <i className="fa-solid fa-bars" onClick={() => setNavToggle(!navToggle)}></i>
        </div>
        {navToggle && (
          <div id="mNav">
            <div>
              <i className="fa-solid fa-xmark" id="closeNav" onClick={() => setNavToggle(!navToggle)}></i>
            </div>
            <div>
              <img src={logo} alt="logo" />
            </div>
            <ul>
              <li>
                <Link smooth to="/">
                  Home
                </Link>
              </li>
              <li>
                <HashLink smooth to="./#services">
                  Services
                </HashLink>
              </li>
              <li>
                <Link to="/About">About us</Link>
              </li>
              <li>
                <HashLink smooth to="/#contactContainer">
                  Contact us
                </HashLink>
              </li>
            </ul>
            <p>© 2020 Orinovait, All Rights Reserved</p>
          </div>
        )}
      </div>
    </>
  );
}

export default Nav;
